<template>
  <v-sheet
    ref="date-picker"
    :width="width"
    :max-height="height"
    class="date-picker overflow-y-auto d-flex flex-column align-center pb-4"
  >
    <header 
      v-if="header"
      class="date-picker-header pa-8 pb-4"
    >
      <v-sheet
        outlined
        class="date-picker-controls d-flex align-center rounded"
      >
        <v-btn
          text
          :color="controller.selected=='start' ? 'primary' : undefined"
          :disabled="started"
          class="date-picker-control date-start-control"
          @click="controller.selected='start'"
        >
          {{ selected.start | formatDate }}
        </v-btn>
        <v-spacer />
        –
        <v-spacer />
        <v-btn
          text
          :color="controller.selected=='end' ? 'primary' : undefined"
          class="date-picker-control date-start-control"
          @click="controller.selected='end'"
        >
          {{ selected.end | formatDate }}
        </v-btn>
      </v-sheet>
      <v-scroll-y-transition>
        <v-alert
          v-show="controller.error!=null"
          dense
          dark
          class="text-body-2 mb-0 mt-2"
          v-html="controller.error"
        />
      </v-scroll-y-transition>
    </header>

    <v-date-picker
      v-for="(month, m) in display"
      :key="`date-display-${m}`"
      v-model="month.selected"
      :first-day-of-week="0"
      :min="min"
      :allowed-dates="isAllowed"
      :show-current="setCurrentMonth(m)"
      :header-date-format="formatMonthHeader"
      :disabled="disabled"
      multiple
      no-title
      locale="pt-br"
      flat
      :class="[`date-display-${m}`, { 'py-4': !header }]"
      @mouseover:date="tooltip"
      @click:date="change"
    />

    <v-icon
      v-intersect="addMonths"
      class="infinite-scroll mt-8"
    >
      {{ icons.down }}
    </v-icon>
  </v-sheet>
</template>

<style>

.date-picker .date-picker-header {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1;
  background: rgba(255, 255, 255, .9);
}
.date-picker .date-picker-header .date-picker-controls {
  width: auto;
}

.v-date-picker-header .v-btn {
  display: none;
}
.v-date-picker-header__value {
  text-transform: capitalize;
  pointer-events: none;
}

.v-date-picker-table .v-btn.v-btn--active.v-btn--disabled {
  color: rgba(255, 255, 255, .6) !important;
}
.date-picker .v-date-picker-table__current:not(.v-btn--active) {
  border: none;
  color: inherit !important;
}
.date-picker .v-btn--disabled.v-date-picker-table__current {
    color: rgba(0, 0, 0, 0.26) !important;
}

</style>

<script>
  import { mdiChevronDown } from '@mdi/js';
  import * as easings from 'vuetify/lib/services/goto/easing-patterns'
  const moment = require('moment');

  export default {
    props: {
      selected: {
        type: Object,
        default: () => {
          return {
            start: '',
            end: ''
          }
        }
      },
      started: {
        type: Boolean,
        default: false
      },
      loading: {
        type: Boolean,
        default: false
      },
      min: {
        type: String,
        default: null
      },
      max: {
        type: String,
        default: null
      },
      header: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      },
      width: {
        type: [String, Number],
        default: 288
      },
      height: {
        type: [String, Number],
        default: '100%'
      },
    },
    
    data: () => ({
      icons: {
        down: mdiChevronDown,
      },
      controller: {
        selected: null,
        error: null,
        focus: false
      },
      display: {},
    }),

    computed: {
    }, 

    watch: {
      selected: {
        immediate: true,
        handler (p) {
          this.setDisplay(p);
        }
      },
      controller: {
        immediate: true,
        deep: true,
        handler (controller) {
          let m;
          if (controller.selected!=null) {
            m = moment(this.selected[controller.selected]).format('YYYY-MM');
          }else{
            m = moment().isBefore(this.selected.end) ? moment() : moment(this.selected.end);
            m = m.format('YYYY-MM');
          }
          if (!controller.focus) {
            this.$nextTick(() => {
              setTimeout(($) => {
                $.$vuetify.goTo('.date-display-'+m, { 
                  container: this.$refs['date-picker'],
                  easing: 'easeInOutCubic',
                  duration: 1000,
                  offset: 24,
                });
                $.controller.focus = true;
              }, 500, this);
            });
          }
        }
      }
    },

    methods: {

      setDisplay (period) {
        const { start, end } = period;
        let pointer = moment().isBefore(start) ? moment().startOf('day') : moment(start);
        let month = moment(pointer).format('YYYY-MM');
        let list = _.assign({}, this.display);
        list = _.mapValues(list, m => { 
          return { selected: [], allowed: [] }
        });
        while (pointer.isSameOrBefore(end)) {
          if (!_.has(list, month)) list[month] = {
              selected: [],
              allowed: [],
            }
          if (pointer.isSameOrAfter(start)) list[month].selected.push(pointer.format('YYYY-MM-DD'));

          pointer.add(1, 'd');
          month = pointer.format('YYYY-MM');
        }
        _.each(list, (month, m) => {
          if (!_.has(this.display, m)) {
            this.$set(this.display, m, month);
          }else{
            this.display[m].selected = month.selected;
            this.display[m].allowed = month.allowed;
          }
        })
        console.log('Period:', list, this.min);
      },

      tooltip (date) {
        console.log(date);
      },

      change (date) {
        console.log(date);
        date = moment(date);
        let start = moment(this.selected.start);
        let end = moment(this.selected.end);
        if (date.isBefore(moment().format('YYYY-MM-DD'))) {
          this.controller.error = 'Não é possível selecionar uma data passada'
          return;
        }else{
          this.controller.error = null;
        }
        const duration = moment(end).diff(start, 'd');
        const middle = moment(start).add(end.diff(start, 'd') / 2, 'd');

        if (this.controller.selected==null) {
          if (!this.started&&(date.isBefore(start)||date.isSameOrBefore(middle))) {
            start = date;
            this.controller.selected = 'end';
          }else {
            end = date;
            // this.controller.selected = 'end';
          }
        }else{
          if (!this.started&&this.controller.selected=='start') {
            start = date;
            this.controller.selected = 'end';
          }else{
            if (!this.started&&this.controller.selected=='end'&&date.isBefore(start)) {
              start = date;
              this.controller.selected = 'end';
            }else{
              end = date;
            }
            this.controller.selected = this.controller.selected=='end' ? null : 'end';
          }
        }

        if (end.isBefore(start)) end = moment(start).add(duration, 'd');

        start = start.format('YYYY-MM-DD');
        end = end.format('YYYY-MM-DD');
        this.update({ start, end });
      },
      
      update (period) {
        console.log(period);
        this.$emit('update', period);
      },

      addMonths () {
        const last = _.last(_.keys(this.display).sort());
        _.each([1,2,3], m => {
          this.$set(this.display, moment(last+'-01').add(m, 'months').format('YYYY-MM'), {
            selected: [],
            disabled: false,
          });
        });
      },

      isAllowed (d) {
        const allowed = _.isNil(this.min) ? moment().isSameOrBefore(d, 'day') : (moment(d).isSameOrAfter(this.min, 'day')&&moment(d).isSameOrBefore(this.max, 'day'))
        return allowed;
      },

      setCurrentMonth (m) {
        return moment(m+'-01').format('YYYY-MM-DD');
      },

      formatMonthHeader (date) {
        return moment(date).format('MMMM YYYY');
      },
    },

    filters: {
      formatDate (d) {
        return moment(d).format('DD/MM/YY');
      }
    },

    mounted () {
    }

  }
</script>